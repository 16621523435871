

@light:'PMNCaeciliaW01-45Light';
@light-italic:'PMNCaeciliaW01-46LightI';
@roman:'PMNCaeciliaETW08-Roman';
@italic:'PMNCaeciliaETW01-Italic';
@bold:'PMNCaeciliaETW01-Bold';
@bold-italic:'PMNCaeciliaETW01-BoldIt';
@heavy:'PMNCaeciliaW01-85Heavy';
@heavy-italic:'PMNCaeciliaW01-86HeavyI';

@iconfont: 'konhef';

/* 
Font Styles
*/

.light{
	font-family:'PMNCaeciliaW01-45Light';
}

.light-italic{
	font-family:'PMNCaeciliaW01-46LightI';
}

.roman{
	font-family:'PMNCaeciliaETW08-Roman';
}

.italic{
	font-family:'PMNCaeciliaETW01-Italic';
}

.bold{
	font-family:'PMNCaeciliaETW01-Bold';
}

.bold-italic{
	font-family:'PMNCaeciliaETW01-BoldIt';
}

.heavy{
	font-family:'PMNCaeciliaW01-85Heavy';
}

.heavy-italic{
	font-family:'PMNCaeciliaW01-86HeavyI';
}


/* 
Fonts
*/

/* 
Base Font
*/

@Caecili: '';


