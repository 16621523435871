/* -------------------------------------------------------------------------
 Reset
---------------------------------------------------------------------------- */

* {
    .box-sizing();
}

/* -------------------------------------------------------------------------
 Typography
---------------------------------------------------------------------------- */
html {
  font-size: 62.5%; /* Sets up the Base 10 stuff */
  margin-top: 0;
}

body {
    .font-size(1.4);
    font-family: @roman;

    &:before{
        content: '';
        display: block;
        position: fixed;
        height: 100%;
        width: 35px;
        margin: auto;
        background-image: url('img/kabel.png');
        left: 50%;
        margin-left: -17.5px;
    }
}

h1, h2, h3, h4 {
   line-height: 1.5
}

h1 {
   .font-size(3.6);
   line-height: 1.22;
   font-family: @heavy-italic;
}

h2 {
  .font-size(3);
   line-height: 1.06;
   font-family: @heavy-italic;
}

h3{
    .font-size(2.4);
   line-height: 1.38;
   font-family: @heavy-italic;
}

p {
   line-height: 1.5;
}

blockquote{
   .font-size(2.1);
   line-height: 1.6;
   font-family: @heavy-italic;
}

strong {
    font-family: @bold;
}


/* -------------------------------------------------------------------------
 Links
---------------------------------------------------------------------------- */
a {
    text-decoration: none;
}



/* -------------------------------------------------------------------------
 Positioning
---------------------------------------------------------------------------- */
.icon-arrow-down
{
    z-index: 200;
}

.pull-right {
    float: right;
}

.nav-wrap ul.sec-nav a:hover
{
    color: @green7;
}

.pull-left {
    float: left;
}

.set-center {
    text-align: center;

    .btn.green{
        .box-shadow(0px 0px 5px @shade7);
        margin: 3px;
    }
}

.visible {
    display: block!important;
}

.hidden {
    display: none!important;
}


/* -------------------------------------------------------------------------
 Icons
---------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------
 Buttons
---------------------------------------------------------------------------- */
section i.round
{
    z-index: 100;
}

.elevator-button:before
{
    cursor: pointer;
}

.btn {
    display: inline-block;

    &.menu{
        background-color: @green2;
        width: 100%;
        .font-size(1.8);
        color: @white;
        text-align: center;
        position: absolute;
        top: 140px;

        &:hover{
            cursor: pointer;
        }

        i{
            margin: 0 0.75em;
        }

        .icon-menu:before{
            .font-size(3);
        }

        span, .icon-caret-down{
            top: -2.75px;
            position: relative;
            font-size: 21px;
            text-transform: uppercase;
            line-height: 2.7em;
        }
    }

    &.green{
        background-color: @green7;
        color: @white;
         .font-size(1.3);
        .border-radius(7px);

        span{
            padding: 1.1em 0.5em 0.7em 1em;
            display: inline-block;
        }

        &:after{
            content: '\f013';
            font-family: @iconfont;
            font-weight: 100;
            .font-size(2.7);
            margin: 0.27em 0.75em 0em 0em;
            float: right;
        }

        &.light{
            background-color: @green2;

            span{
                font-family: @roman;
            }
        }

        &.small {
            .font-size(1.3);
            
            span {
                padding: .5em 0.9em 0.5em 1em;
            }

            &:after{
                .font-size(1.8);
            }
        }
      
    }

    &.blue{
        background-color: @blue4;
        color: @white;
         .font-size(1.3);
        .border-radius(7px);

        span{
            padding: .5em 0.9em 0.5em 1em;
            display: inline-block;
        }

        &:after{
            content: '\f013';
            font-family: @iconfont;
            font-weight: 100;
            .font-size(1.8);
            margin: 0.27em 0.75em 0em 0em;
            float: right;
        }
      
    }

    &.round{
        display: block;
        float: left;
        text-align: center;
        margin-bottom: 1em;
        padding: 1em;

        &:before{
            color: @white;
            background-color: @green2;
            width: 50px;
            height: 50px;
            .font-size(4.5);
            line-height: 1.1;
            border-radius: 50%;
            text-align: center;
            
            border: solid @green4 8px;
            background-clip: padding-box;
        }


        &.icon-arrow-double:before{
            background-color: @green9;
             // .font-size(8);
             // line-height: 1.04 ;
             .font-size(4.5);
             line-height: 1.1;
             .text-shadow(0 0 10px @white, 0 0 20px @white, 0 0 30px @white, 0 0 40px @white, 0 0 70px @white, 0 0 80px @white, 0 0 100px @white, 0 0 150px @white;);
        }

        &.gray:before{
            background-color: @shade4;
        }

        &.spacer{
            position: absolute;
            padding: 0;
            width: 100%;
            margin: 0;
            height: 30px;
            background-color: @green2;
            border-top: @shade10 12px solid;

            
            &:before{
                margin-top: -40px;
                position: relative;
            }

        }
        
        a{
            display: inline-block;
            position: absolute;
            margin: auto;
            margin-top: -69px;
            height: 90px;
            width: 90px;
            left: 0;
            right: 0;
        }

    }


}


/* -------------------------------------------------------------------------
 SVG Logo's
---------------------------------------------------------------------------- */

.svg-logo-link,
{
    background-repeat: no-repeat;
    display: block;
    background-image: url('img/logo.svg');
    width: 90px;
    height: 90px;
    background-color: @shade4;
    background-position: center;

    &.site-logo {
        margin-left: 0.5em;
        width: 118px;
        height: 118px;
        background-color: @green7;
        .border-radius(10px);
         background-size: 95px;
         .box-shadow(0px 0px 10px @shade5);

        span object {
          svg{  
           
            }
        }
    }
}


/* -------------------------------------------------------------------------
 Images
---------------------------------------------------------------------------- */

img {
    display: block;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

/* -------------------------------------------------------------------------
 Breadcrumb
---------------------------------------------------------------------------- */

.breadcrumb{
 

    ul
    {
      width: 100%;
      display: block;
      overflow: hidden;
      padding: 20px 0;
      font-family: @light;

        li
        {
            float: left;
            color: @shade6;
            padding: 0 1em 0 0;
            
            a
            {
                color: @shade6;
                padding: 0 1em 0 0;
            }

            &:after
            {
                content: '>>'
            }

            &:last-child:after
            {
                content: '';
            }   
        }
    }
}


/* -------------------------------------------------------------------------
 Lists
---------------------------------------------------------------------------- */

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

 ul.list{
    margin: 30px 15px;
    list-style: none;
    background-color: @white;
    position: relative;

    li {
        font-family: @heavy-italic;
        color: @green7;
        padding: 1em 37px;

        &:nth-child(odd){
            background-color: @opacity1;
        }

    }
}



/* -------------------------------------------------------------------------
 Form elements
---------------------------------------------------------------------------- */



input[type="text"], 
input[type="search"], 
input[type="submit"], 
input[type="checkbox"], 
textarea {
    -webkit-appearance: none;
    -moz-appearace: none;
    appearance: none;
    outline: none;
    border: none;
    .font-size(1.2);
}

input[type="text"],
textarea {
    background-color: @opacity1hex;
    width: 100%;
    display: inline-block;
    height: 40px;
    margin: 0.5em 0;
    padding: 1em;
    position: relative;
    font-family: @roman;
}

textarea {
    width: 100%;
    height: 180px;
     resize: none;
}

input[type="submit"].btn {
    .font-size(1.6);
    font-family: @roman;
    color: @white;
    width: 100%;
    background-color: @green7;
    text-align: center;
    display: inline-block;
    padding: 1em 0 0.7em 0;
    position: relative;
}

label{
    .font-size(1.6);
    color: @green7;
    margin: 0.5em 0 0 0;
    display: inline-block;

    &.required:after{
        content: '*';
        margin-left: 0.2em;
    }
}



.validation_error, .validation_message{
    color: red;
}


/* -------------------------------------------------------------------------
 Tables
---------------------------------------------------------------------------- */

table {
    width: 100%;
    text-align: left;
    margin: 20px 0;
    background-color: @white;
    position: relative;
    line-height: 1.5;
        
    tr {
    
        th{
            background-color: @shade9;
            font-family: @heavy;
            color: @shade3;
            display: none;

        }

        td, 
        th {
            padding: 0.6em;  
        }

        td{
            display: block;
            width: 100%;
            float: left;
            .font-size(1.4);

            &:first-child{
                color: @green7;
                font-family: @heavy-italic;
                 padding-top: 1em;
            }

            &:nth-child(2){
                width: 40%;
                padding-bottom: 1em;
            }

             &:nth-child(3){
                width: 60%;
            }

        }
        
        &:nth-child(odd) {
            background-color: @opacity1;
        }
    }


    &.green{
      
        tr{
            th, th:nth-child(2){
                color: @white;
                background-color: @green7;
                border: none;
                font-family: @italic;
                .font-size(1.7);
            }

            td{
                vertical-align: middle;
                font-family: @heavy-italic;
                 .font-size(1.4);

                &:nth-child(2){
                    font-family: @roman;
                    color: @shade3;
                }
            }
        }        
    }

}

/* -------------------------------------------------------------------------
 WYSIWYG
---------------------------------------------------------------------------- */

.wysiwyg {

    p, li{
        line-height: 1.5;
    }

    ul{
        list-style: disc;
        margin-left: 50px;
    }

    h3{
        .font-size(1.7);
        color: @green7;
        margin: 10px 0;
    }

    a {
        color: @green7;
    }
}

/* -------------------------------------------------------------------------
Page - Specific Styling
---------------------------------------------------------------------------- */

.page-id-53
{
    .blocks.timelinePiece
    {
        overflow: visible;
    }
}

.page-id-17
{
    .btn
    {
        &.green
        {
            span
            {
                width: 80%;
                line-height: 1.3;
                margin-left: 2%;
            }

            &:after
            {
                margin: 0.5em 0.75em 0em 0em;
            }
        }
    }
}

/* -------------------------------------------------------------------------
Firefox - Specific Stying
---------------------------------------------------------------------------- */

@-moz-document url-prefix()
{
    nav div.logo
    {
        top: 1px;
    }

    header nav a.btn.menu
    {
        height: 50px;
    }

    .btn.menu 
    {  
        padding: 0.4em;

        span, .icon-caret-down
        {
            top: -3.75px;
            line-height: normal;
        }
    }

    .icon-caret-down::before
    {
        position: absolute;
        top: -7.75px;
    }
}

/* -------------------------------------------------------------------------
Safari - Specific Styling
---------------------------------------------------------------------------- */
@media screen and (-webkit-min-device-pixel-ratio:0)
{
    ::i-block-chrome, nav a.btn.menu
    {
        top: 123px;
    }

    .tagline
    {
        margin-top: 9px;
    }
}