/* -------------------------------------------------------------------------
 Navigation
---------------------------------------------------------------------------- */

nav
{
	background-color: @shade10;

	li, a{
		display: block;
	}

	a.btn.menu
	{
		margin-top: -20px;
	}

	.sec-nav{
		overflow: visible;
		position: relative;

		.nl, .fr, .vragen{
			background-color: @green2;
			top: 0px;

			a{
				.font-size(1.4);
				color: @white;
				padding: 0.em 1em 0.3em 0.75em;
				font-family: @light;
			}		
		}

		.nl, .fr{
			width: 3em;
		}

		.nl a{
			padding-left: 1.5em;
		}

		.vragen{
			width: 100%;
			float: none;
			z-index: 0;
			text-align: right;
			height: 32px;

			a{
				padding-right: 1.5em;
			}
		}

		li{
			float: left;
			width: 50%;
			background-color: @shade10;
			text-align: right;
			position: relative;
			top: 138px;
			z-index: 99;

			a{
				box-sizing: border-box;
				height: 32px;
				width: 100%;
				padding-top: 8px;
				padding-left: 10px;
				padding-right: 10px;
				//padding: 0.5em 0.75em 0.8em;
				.font-size(1.5);
				color: @shade2;
			}

			&.mijn{
				text-align: left;
				display: none;
			}

			&.exp{
				text-align: left;
			}

			&.current_page_item{
				a{
					color: @green7;
				}
			}
		}

		ul:nth-child(2){
			li:nth-child(n+2){
				display: none;
			}

			li:last-child{
				width: 100%;
				text-align: center;
				padding-right: 159px;
			}
		}


	}

	.logo
	{
		width: 100%;
		height: 138px;
		display: block;
		padding-top: 1px;
		position: relative;

		.svg-logo-link.site-logo{
			margin: auto;
			position: relative;
			width: 85px;
			height: 85px;
			background-size: 75px;
		}
	}

	.menu{
		position: relative;
		top: 26px;
		.box-shadow(0px 0px 10px @shade5);
	}

	.prim-nav{
		position: absolute;
		display: none;
		z-index: 999;
		width: 100%;
		margin-top: 4.5em;

		li{
			a{
				width: 100%;
				background-color: @green2;
				color: @white;
				.font-size(1.8);
				padding: 1em;
				text-align: center;
			}

			&:hover{
				background-color: @green10;

				a{
					background-color: @green10;
				}
			}	

			a{
				border-top: solid 1px @green1;
			}
		}
	}	
}

footer#site-footer
{
	.btn.round.spacer
	{
		margin: -30px 0 0 0;
		height: 30px;
	}
	.prim-nav
	{
		ul
		{
			z-index: 2000;
		}
		li
		{
			z-index: 50;
			&:hover
			{
				background-color: @green10;
				a
				{
					background-color: @green10;
				}
			}
		}
	}
}


//active item prim-nav

.page-id-12 nav .prim-nav #menu-item-33,
.page-id-17 nav .prim-nav #menu-item-34,
.page-id-19 nav .prim-nav #menu-item-35,
{
	background-color: @green10;

	a{
		background-color: @green10;
	}
}



/* -------------------------------------------------------------------------
 Header home
---------------------------------------------------------------------------- */

.tagline{
	background-image: url('img/background-small.jpg');
	background-position: center;
	background-repeat: no-repeat;
	display: block;
	position: relative;
	padding: 35px 0;
	margin-top: 0;

	.quoute{
		background-color: @opacity2;
		position: relative;
		padding: 1em 0;

		p{
			.font-size(1.8);
			font-family: @heavy-italic;
			text-align: center;
			padding: 0 0.5em;

			em{
				color: @green7;
			}

			&:last-child{
				color: @green2;
				.font-size(3);
				padding: 0.2em;
				margin-top: 0.3em;
				line-height: 1.1;
			}
		}
	}
}

.announcements {
	position: relative;
	display: block;
    width: 100%;
    background-color: #E1E5E3;
    padding: 3em 0;

    .announcement {

    	article {

    		h1 {
	    		font-size: 2.8rem;

	    		a {
	    			color: black;
	    		}
	    	}

	    	h2 {
	    		font-size: 2.4rem;
	    		margin: .2em 0;

	    		a {
	    			color: black;
	    		}
	    	}

	    	p {
	    		padding: 1em 0;
	    		color: @shade6;
	    		font-family: @bold-italic;
	    	}

	    	&.vacature {

	    		h2 {
	    			color: @blue4;
	    		}
	    	}

	    	&.nieuws {

	    		h2 {
	    			color: @green2;
	    		}
	    	}
    	}

    	&.announcement-single {

    		img {
    			margin: 0 auto;
    		}
    	}

    	&.announcement-duo{
    		article{
    			margin-bottom: 2em;
    		}
    	}
    }
}

.quoute-page{
	.box-shadow(0px 0px 8px @shade7);
	padding: 0.3em;
	margin-bottom: 25px;
	background-color: @white;
	position: relative;

	header.pagina_afbeelding
	{
		overflow: hidden;

		.imageHolder
		{
			height: 90px;
			background-size: 100%;
			background-position: center top;
			margin-bottom: 12px;
		}
	}

	h1{
		color: @green7;
		.font-size(2);
		padding: 0.5em 0;
	}

	img{
		width: 100%;
	}

	p{
		color: @green2;
		font-family: @italic;
		font-size: 17px;
		padding: 0 1em;

		span{
			color: @shade3;
			.font-size(1.1);
			width: 100%;
			display: inline-block;
			padding: 1em 0 1em;
			font-family: @italic;
		}
	}
}

/* -------------------------------------------------------------------------
 Footer
---------------------------------------------------------------------------- */

footer#site-footer{
	 background-color: @shade4;
	 z-index: 999;
	 position: relative;
	 margin-top: 100px;

	.prim-nav{
		

		ul{
			width: 100%;
			display: block;

			li{
				
				display: block;
				text-align: center;
				background-color: @green2;
				border-top: @green1 1px solid;

				a{
					display: table;
					height: 50px;
					width: 100%;
					font-family: @italic;

					span{
						display: table-cell;
						vertical-align: middle;
						color: @white;
						.font-size(1.8);
					}
				}

				&.active{
					background-color: @green10;
				}
			}

		}

	}

	.container{
		text-align: center;
		padding-top: 1em;
		padding-bottom: 1em;


		.svg-logo-link{
			margin: 1em auto;
			background-color: transparent;
		}

		p{
			width: 172px;
			margin: auto;
			padding: 1em 0;
			line-height: 2;
			color: @shade9;
			font-style: italic;

			i{
				padding: 0 10px;
				.font-size(2);
				margin-left: -35px;

				&.icon-fax{
					.font-size(2.2);
				}
			}
		}

		.btn{
			width: 100%;
			margin: 0.5em 0;
			.box-shadow(0px 0px 5px @shade3);
		}
	}
}

/* -------------------------------------------------------------------------
	Timeline
---------------------------------------------------------------------------- */

.timeline{
	width: 100%;
	overflow: hidden;
	display: block;
	position: relative;
	padding-top: 2em;

	ul{
		display: block;
		height: 130px;
		width: 1100%; 
		margin-left: -400%;


		.left, .right{
			display: block;
			color: @white;
			background-color: @blue4;
			.font-size(3);
			padding: 6px;
			position: absolute;
			margin-top: 1.15em;
			.border-radius();
			font-family: @iconfont;
			z-index: 999;

			&:hover{
				cursor: pointer;
			}
		}

		.left{
			left: 10px;
		}

		.right{
			right: 10px;
		}

		li{
			width: 9.09090909%;
			float: left;
			display: block;
			.font-size(1.2);
			padding: 0 55px;
			margin-top: 50px;

			article{
				display: block;
				width: 200px;
				margin: auto;

				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 15px 15px 15px 0;
					border-color: @green7 transparent transparent transparent;
					position: relative;
					left: -5px;
					top: -5px;
					margin-left: 7px;
				}

				span:first-child{
					background-color: @green7;
					top: -37px;
					padding: 10px;
					position: relative;
					.font-size(1.3);
					font-family: @heavy;
					color: @white;
					left: -20px;
				}

				span:last-child{
					display: block;
					position: relative;
					float: left;
					color: @black;
				}
			}
		}
	}

	&:after{
		content: '';
		width: 100%;
		position: relative;
		z-index: 99;
		border-bottom: 2px solid @blue3;
		display: block;
		top: -80px;
	}
}

/* -------------------------------------------------------------------------
 Content blocks
---------------------------------------------------------------------------- */

.blocks{
	/* -------------------------------------------------------------------------
	 Intro
	---------------------------------------------------------------------------- */


	&.intro{

	   p{
	        padding: 0 1em;
	        .font-size(1.5);
	        line-height: 1.6;
	        color: @shade3;
	   }
	}

	&#contact
	{
		padding-bottom: 50px;
	}
	/* -------------------------------------------------------------------------
	 Timeline piece
	---------------------------------------------------------------------------- */

	&.timelinePiece{
		padding: 35px 0;
		display: none;

		article{
			overflow: hidden;
			margin-bottom: 25px;

			header{
				span{
					background-color: @green7; 
					.font-size(1.2);
					padding: 0.6em 1em;
					display: inline-block;
					margin: 0.5em 0;
					color: @white;
					font-family: @bold;
				}

				h3{
					font-family: @roman;
					color: @green11;
					margin: 0.5em 0;
					.font-size(1.6);
				}
			}

			aside img{
				width: 100%;
				margin: 0.5em 0;
				display: inline-block;
			}

			section{
				.font-size(1.5);
				color: @shade5;
				display: inline-block;
				margin: 0.5em 0;
				font-family: @light;
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Home block
	---------------------------------------------------------------------------- */

	&.home{
		background-color: @shade5;
		position: relative;
		z-index: 99;
		
		article{

		
			.spacer{
				height: 0px;
			}

			header{
				padding-top: 45px;
				display: inline-block;
				width: 100%;

				h3{
					color: @white;
					text-align: center;
					margin-bottom: 10px;
					font-family: @italic;
				}

				img{
					border: solid 5px @white;
					width: 100%;
					.box-shadow(0px 0px 5px @shade1);
				}
			}

			section{
				padding: 20px 25px 0px 25px;
				color: @white;
				.font-size(1.8);
				font-family: @light;
				line-height: 1.5;
			}

			footer{
				background-color: transparent;
				padding: 25px 25px 45px 25px;

				a{
					.font-size(1);
					.box-shadow(0px 0px 6px @shade4);

					&:after{
						.font-size(2);
						margin: 0.2em 0.75em 0em 0em
					}
				}
			}
		}

		&.alt
		{
			background-color: @white;
			padding-bottom: 40px;

			article
			{
				header
				{ 
					h3
					{
						color: @shade5;
					}

					img
					{
						.box-shadow(0px 0px 8px @shade7);
					}
				}

				section
				{
					color: @shade5;
				}

				footer
				{
					a
					{
						.box-shadow(0px 0px 5px @shade7);
					}
				}
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Expertise block
	---------------------------------------------------------------------------- */

	&.expertise{
		article{
			header{
				h3{
					color: @green7;
					padding: 1em 0;
					width: 100%;
				}
			}

			section{
				font-family: @italic;
				color: @green2;
				line-height: 1.4;
				.font-size(2.1);
				padding: 0 0 1em 0;
			}

			footer{
				background-color: transparent;
				color: @shade3;
				.font-size(1.4);
				padding: 0 0 1em 0;
				margin-top: 0px;

				&:before{
					content: '-';
				}
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Controle block
	---------------------------------------------------------------------------- */

	&.controle{
		article{
			header{
				span{
					display: inline-block;
					font-family: @italic;
					color: @white;
					padding: 0.6em 0.5em 0.4em 1em;
					min-width: 160px;
					background-color: @green2;
				}

				h3{
					color: @green7;
					padding: 0.5em 0;
					width: 100%;
				}
			}

			section{
				font-family: @italic;
				line-height: 1.7;
				.font-size(1.4);
				padding: 0 0 1em 0;
				font-family: @roman;

				span, a{
					color: @green7;
					font-family: @heavy;
				}
			}
		}
	}

	/* -------------------------------------------------------------------------
	 Contact gegevens block
	---------------------------------------------------------------------------- */
	
	&.contactgeg{
		.font-size(1.4);
		line-height: 2;
		color: @shade3;
		margin-top: 2em;

		a{
			width: 100%;
			display: inline-block;
			color: @shade3;
		}

		p{
			line-height: 1.8;
		}

		h3{
			.font-size(1.7);
			color: @green7;
			margin-bottom: 10px;
		}
	}
}


/* -------------------------------------------------------------------------
 Slider
---------------------------------------------------------------------------- */

.slider{
	padding: 10px;
	background-color: @opacity1;
	position: relative;
	margin-bottom: 30px;

	.btn.round{
		position: absolute;
		left: -20px;
		top: 45%;
		padding: 0;
		z-index: 99;

		&:last-child{
			right: -20px;
			left: inherit;
		}

		&:before{
			width: 33px;
			height: 33px;
			.font-size(3.1);
			border: solid @green4 4px;
			line-height: 1.1;
		}
	}

	section{
		overflow: hidden;
		position: relative;

		ul{
			width: 300%;

			li{
				img{
					width: 33.33%;
					float: left;
				}
			}
		}
	}

	.btn:hover{
		cursor: pointer;
	}

	&.contact{
		width: 85%;
		margin: 1em auto;

		.btn.round{
			top: 35%;
		}
	}

}

/* -------------------------------------------------------------------------
 Map
---------------------------------------------------------------------------- */

.map{
	border-bottom: @green2 solid 6px;
	background-color: @green2;
	margin: 50px 0 30px 0;

	iframe{
		width: 100%;
	}
}

/* -------------------------------------------------------------------------
 Template specific
---------------------------------------------------------------------------- */

.page-template-tpl_tijdlijn-php{
	.blocks.timelinePiece{
		position: inherit;
		height: auto;
		margin-top: 0;
		display: block;

		article{
			height: auto;
		}
	}
}

.page-template-tpl_home-php{
	#site-footer {
		margin-top: 0;
	}
}