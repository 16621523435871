/* -------------------------------------------------------------------------
 General
---------------------------------------------------------------------------- */

body,
html {
    height: 100%;
}


.container {
	width: 100%;
	padding: 0;
	margin: 0 auto;
	.box-sizing();
    padding: 0 16px;
}

.container-small {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    .box-sizing();
    position: relative;
    padding: 0 2em;
}

/* -------------------------------------------------------------------------
 Grid
---------------------------------------------------------------------------- */

.content{
    width: 100%;
    overflow: hidden;
}

.sidebar{
    width: 100%;
}

.col1, .col2, .col3, .col4{
    li, ul{
        width: 100%;
    }
}







