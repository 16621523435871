@media screen and (min-width: @bp1) {
	// 480/700
	
	.page-id-53, .page-id-267
	{
		.blocks.timelinePiece
		{
			overflow: visible;
		}
	}


	.container{
		padding: 0 24px;
	}
	

	.btn
	{
		&.green
		{
			 .font-size(1.5);

			 &:after
			 {
			 	.font-size(3);
			 	margin: 0.27em 0.5em 0em 0em;
			 }
		}
	}

	.slider{
		width: 380px;
		margin: auto;

		&.contact{
			.btn.round{
				top: 40%;
			}
		}
	}

	.blocks{

		&.home{
			article{
				.container{
					padding:0 52px;

					header, section, header img, footer{
						padding-left: 0;
						padding-right: 0;
					}

					header h3{
						.font-size(2.8);
					}

					section{
						.font-size(1.4);
					}
				}
			}
		}
	}

	.green_button_holder
	{
		text-align: center;
		margin-top: 20px;
	}
}

@media screen and (min-width: @bp2) 
{
	// 768/988

	.container{
		position: relative;
		padding: 0 38px;
		overflow: hidden;
	}

	.quoute-page header.pagina_afbeelding .imageHolder
	{
		height: 300px;
	}

	label{
		width: 30%;
		float: left;
		margin-top: 1.2em;
	}

	input[type="text"],
	textarea {
		width: 70%;
	}

	input[type="submit"].btn {
		width: 170px;
		margin-left: 30%;
	}

	ul.list {
    	margin: 30px 0px;
	}

	p {
		line-height: 1.7;
	}

	.tagline
	{
		.container
		{
			.quoute
			{
				p:first-child
				{
					width: auto;
				}
			}
		}
	}

	.btn{
		&.round{
			&:before{
				width: 80px;
	            height: 80px;
	            .font-size(6);
	            line-height: 1.3;
			}

			 &.icon-arrow-double:before{
			 	.font-size(7.5);
			 }

			 &.spacer:before{
			 	margin-top: -55px
			 }

			 a{
			 	width: 90px;
			 	height: 90px;
			 	top: 15px;
			 	z-index: 999;
			 }
		}
	}

	header.main{
		background-image: linear-gradient(top, @shade10, @shade10 52px, transparent 52px, transparent 100%);
	}

	nav
	{
		background-color: transparent;
		background-image: none;
		width: 100%;
		overflow: hidden;
		padding: 0 38px;

		.sec-nav{
			background-color: @shade10;
			overflow: hidden;

			li{
				padding: 1em 0;
				top: inherit;
				width: auto;

				a{
					.font-size(1.1);
					color: @shade2;
					padding: 0.5em 1em;
					border-left: solid 1px @green8;
					display: block;

				}

				&.nl, &.fr{
					background-color: transparent;
					width: auto;

					a{
						border-left: none;
						.font-size(1.1);
						color: @shade6;
						padding: 0.5em 0.5em;
						font-family: @roman;
					}

					&.active a{
						color: @green7;
					}
				}

				&.nl a{
					padding-left: 1em;
				}

				&.fr a{
					padding-right: 1em;
				}

				&.mijn{
					background-color: @blue4;
					display: block;
					
					a{
						border-left: none;
						color: @white;
					}
				}

				&.route a{
					border-right: none;
				}

				&.vragen {
					width: auto;
					background-color: transparent;

					a{
						color: @shade6;
						.font-size(1.1);
						padding: 0.5em 1em;
						font-family: @roman;
					}
				}

				&:last-child a{
					border-left: none;
				}

				&.pull-left{
					float: left;
					padding-bottom: 0;
				}

				&.pull-right{
					float: right;
				}
			}

			ul:nth-child(2){
				li:nth-child(n+2){
					display: block;
					padding-bottom: 5px;
				}

				li:last-child{
					width: inherit;
					padding-right: inherit;
				}
			}
		}

		.menu{
			display: none;
		}

		.logo{
			top: inherit;
			float: left;
			background-color: transparent;
			height: auto;
			margin-top: 10px;
			margin-bottom: 10px;

			.svg-logo-link.site-logo{
			
			}
		}

		.prim-nav{
			float: left;
			width: 100%;
			top: inherit;
			display: block;
			position: relative;
			.box-shadow(0px 0px 3px @shade4);
			margin-top: 0;

			li{
				display: block;
				float: left;
				width: 25%;
				border-collapse: collapse;
				background-color: @green2;
				border-right: solid 1px @green3;

				&.active{
					background-color: @green10;
				}

				a{
					display: table;
					height: 60px;
					width: 100px;
					margin: auto;
					border-top: none;

					span{
						
						display: table-cell;
						color: @white;
						.font-size(1.8);
						vertical-align: middle;
						
						height: auto;
						padding: 0;
					}
				}

				&:nth-child(2) a span{
					width: 130px;
				}

				&:last-child{
					border-right: none;
				}
			}
		}
	}

	.tagline{
		background-image: url('img/background-medium.jpg');
		margin-top: 0;

		.quoute{
			margin: 0;
			background-color: transparent;

			p{
				background-color: transparent;
				float: none;

				span{
					display: inline-block;
					.font-size(2.6);
					background-color: @opacity2;
					padding: 1em;
					position: relative;

					&:first-child{
						width: 690px;
					}

					&:last-child{
						width: 550px;
						margin-top: -1.1em;
						padding-top: 0;
						z-index: 99;
					}
				}

				&:last-child{
					width: 605px;
					margin: auto;
					background-color: @opacity2;
					.font-size(3.1);
					padding: 0 0.8em 0.8em 0.8em;
					margin-top: -0.6em;
					z-index: 999;
					position: relative;
				}
			}
		}
	}

	.timeline
	{
		margin-left: 0;
		background-image: url('img/ptrn_timeline.png');
		padding-top: 0;
		height: 170px;

		ul{
			padding-top: 5em;
			width: 2610px;
			margin-left: 0;

			li{
				width: auto;
				margin-top: 0;
				padding-left: 150px;
				border-top: none;

				article{
					width: auto;
					overflow: hidden;

					&:before{
						position: absolute;
						left: inherit;
						top: inherit;
						margin-left: 0px;
						margin-top: 33px;
					}

					span:first-child{
						top: inherit;
						left: inherit;
						float: left;
					}

					span:last-child{
						float: right;
						margin: 11px 0 0 10px;
					}

					&:hover, &.active{
						cursor: pointer;
						
						&:before{
							border-color: @blue4 transparent transparent transparent;
						}

						span:first-child{
							background-color: @blue4;
						}

						span:last-child{
							color: @blue4;
						}
					}
				}



				&.start:nth-child(even){
					margin-left: -90px;
				}

				&:nth-child(even){
					margin-top: -4.2em;
					padding-bottom: 15px;
					position: relative;
					margin-left: -260px;
				}


				&:nth-child(odd){
					padding-top: 14px;
					padding-left: 100px;

					article{
						&:before{
							margin-top: -15px;
							border-width: 15px 15px 0px 15px;
							border-color: transparent transparent transparent @green7;
						}

						&:hover, &.active{
							&:before{
								border-color: transparent transparent transparent @blue4;
							}
						}
					}
				}
			}

			.left, .right{
				margin-top: -0.62em;
			}
		}

		&:after{
			top: -63px;
		}
	}


	.blocks{

		&.intro{
			p{
				padding: 0;
			}	
		}

		&#contact
		{
			padding-bottom: 0px;
		}

		&.timelinePiece{
				background-color: @opacity2;
				position: relative;
				z-index: 999;
				padding: 0;
				height: 0;
				margin-top: 0;
				overflow: hidden;
				display: block;

				.icon-arrow-down{
					display: block;
					width: 22px;
					height: 22px;
					position: absolute;
					right: 35px;
					top: 25px;
					text-align: center;

					&:before{
						.font-size(3);
					}


					&:hover{
						cursor: pointer;
					}
				}

				article{
					width: 695px;
					margin: auto;
					padding: 20px 0;
					height: 292px;

					header{
						float: right;
						width: 380px;

						h3{
							.font-size(1.3);
							line-height: 2;
							float: left;
							margin-bottom: 0;
						}

						span{
							position: relative;
							.font-size(1.3);
							padding: 0.4em 0.5em;
							margin-right: 0.5em;
							float: left;
							margin-bottom: 0;
						}
					}

					aside img{
						width: 300px;
						float: left;
					}

					section{
						float: right;
						font-family: @italic;
						.font-size(1.7);
						width: 380px;
						margin: 10px 0 0 0;
					}

					&.noimage{
						width: 475px;
					}
				}
		}

		&.home
		{
			article{
				padding-bottom: 70px;


				.container{
					padding:0 113px;c

					header
					{ 
						h3{
							.font-size(4.3);
						}

						img{
							width: 100%;
						}
					}

					header{
						padding-top: 70px;
					}

					section{
						.font-size(2);
					}


					footer{
						a{
							.font-size(1.5);

							span{
								padding: 1em 0.5em 0.7em 1em
							}

							&:after{
								.font-size(3);
							}
						}
					}
				}
			}

			&.alt
			{
				padding-bottom: 0;
			}
		}
	}

	footer#site-footer
	{
		padding-bottom: 10em;

		.btn.round:before
		{
			position: relative;
			z-index: 10;
		}

		.btn.round.spacer
		{
			margin: 0;
			height: 30px;
			z-index: 99;
			margin-top: -24px
		}

		.prim-nav
		{		
			padding: 0 38px;

			ul
			{
				z-index: 9;
				position: relative;
				.box-shadow(0px 0px 8px @shade4);
				overflow: hidden;

				li
				{
					width: 25%;
					float: left;
					border-top: 0;
					border-right: @green1 1px solid;
					padding: 0;

					&:last-child
					{
						border-right: none;
					}

					a
					{
						width: 80px;
						margin: auto;
							
						span
						{
							.font-size(1.3);
						}
					}
				}
			}
		}
	}

	.slider{
		&.contact{
			.btn.round{
				top: 45%;
			}
		}
	}

	footer#site-footer .container
	{
		display: block;
		overflow: hidden;

		.svg-logo-link
		{
			width: 47%;
			float: right;
			background-position: left;
			margin-top: 98px;
		}

		p
		{
			float: left;
			padding-top: 0;
			width: 47%;
			margin-bottom: 20px;
			margin-left: 0;
			margin-top: 45px;
			text-align: right;
			line-height: 3;
		}

		.btns
		{
			text-align: left;
			float: right;
			width: 47%;

			.btn
			{
				min-width: 230px;
				max-width: 250px;

				&.green
				{
					.font-size(1.1);

					&:after
					{
						.font-size(2);
					}
				}
			}
		}
	}

	@-moz-document url-prefix()
	{
		nav div.logo
    	{
 	       	top: auto;
    	}

    	section.timeline ul li article:before 
    	{
      		position: absolute;
      		left: inherit;
      		top: inherit;
      		margin-left: -50px;
      		margin-top: 33px;
    	}
	}	
}

@media screen and (min-width: @bp3) {
	// 940/1160

	.container{
		padding: 0 47px;
	}

	.col3{
		li{
			width: 30.33%;
			margin: 0 1.5%;
			float: left;
		}
	}

	

	table{
		tr{
			th{
				display: table-cell;
			}
			

			 td, th{
			 	&:nth-child(2){
	                border-left: solid 1px @white;
	                border-right: solid 1px @white;
	            }
	           
	            padding: 1em;
			 }

		  	td, td:nth-child(2), td:nth-child(3){
            	width: 33.33%;
            }

			 td{
			 	display: table-cell;
			 	float: inherit;
			 	.font-size(1.4);
			 }

			&:nth-child(even) {
	            background-color: @opacity1;
	        }

	        &:nth-child(odd) {
	            background-color: transparent;
	        }

		}
	}

	header.main{
		background-image: linear-gradient(top, @shade10, @shade10 52px, transparent 52px, transparent 100%);
	}

	nav{
		padding: 0 47px;

		.sec-nav{
			li, li.vragen, li.nl, li.fr{
				a{
					.font-size(1.2);
				}
			}
		}

		.logo{
			height: 75px;

			.svg-logo-link.site-logo{
				margin: -6px auto;
			}
		}
	}

	.tagline{
		background-image: url('img/background-big.jpg');
	}

	.announcements {

	    .announcement {

	    	article {
	    		display: flex;
	    		flex-direction: column;
	    		justify-content: center;
	    	}

	    	&.announcement-duo {
	    		display: flex;

	    		article {
	    			padding-right: 5%;
	    			width: 50%;
	    			float: left;
	    			margin-bottom: 0;
	    		}
	    	}

	    	&.announcement-single {

	    		article {
	    			margin-left: 50%;

	    			img {
	    				margin-left: -50%;
	    			}

	    			h1 {
			    		position: absolute;
			    		left: 0;
			    		width: 48%;
			    		text-align: right;
			    		margin: .5em 0;
			    	}

			    	h2 {
			    		margin: 0;
			    	}

	    		}
	    	}
	    }
	}

	.quoute-page{
		position: relative;
		padding: 0.4em;

		header{
			position: relative;
		}

		h1{
			.font-size(3.6);
			display: inline-block;
			background-color: @opacity2;
			padding: 0.65em 1.5em; 
			z-index: 99;
			position: absolute;
			top: 33%;
		}

		img{
			top: 0;
			left: 0;
			width: 100%;
		}

		p{
			display: block;
			position: relative;
			padding: 1em 2em;
			.font-size(2.1);

			span{
				.font-size(1.4);
			}
		}
	}

	.blocks{
		&.timelinePiece{
			article{
				width: 840px;

				header, section{
					width: 475px;
				}

				aside img{ 
					width: 350px;
				}
			}
		}

		&.home{
			z-index: 1;
			position: inherit;

			article{
				padding-bottom: 0;

				.container{
					padding: 0 47px;
					overflow: hidden;
				

					header{
						width: 45%;
						float: left;
						padding-bottom: 10em;

						h3{
							.font-size(3);
							text-align: left;
						}
					}

					section{
						.font-size(1.7);
						line-height: 1.75;
						padding-top: 5.5em;
					}

					section, footer{
						width: 45%;
						float: right;

						a{
							.font-size(1.1);
							padding: 0.25em;

							&:after{
								.font-size(1.7);
								margin: 0.4em 0.75em 0em 0em
							}
						}
					}

				}
			}
		}
	}	
	

	footer#site-footer {
		.prim-nav{
			padding: 0 47px;
		}
	}

}


@media screen and (min-width: @bp4) {
	// 1300/1520 

	.content{
		width: 67.4%;
		float: left;
	}

	.sidebar{
		width: 27.5%;
		float: right;
	}

	.slider{
		margin: 20px auto;
		width: 100%;
	}
	

	.container{
		width: 1200px;
		padding: 0;
	}

	.col4{
		li, ul{
	    	width: 25%;
		}
	}

	.sidebar{
		width: 30%;
	}

	.slider{
		width: 88%;
	}

	nav{
		width: 1200px;
		margin: auto;
		padding: 0;

		.sec-nav{
			li, li.vragen, li.nl, li.fr{
				a{
					.font-size(1.5);
				}
			}
		}

		.logo{ 
			height: 95px;

			.svg-logo-link.site-logo{
				width: 120px;
				height: 120px;
				background-size: 100px;
				margin: -60px auto;
			}
		}
	}

	//Header not home
	nav.nothome{
		overflow: inherit;
		.prim-nav{
			li{
				a{
					height: 90px;
					width: 120px;

					span{
						.font-size(2.4);
					}
				}

				width: 23%;

				&:nth-child(2){
					margin-right: 8%;
				}
			}
		}

		.logo{ 
			height: 0;
			padding: 0;

			.svg-logo-link.site-logo{
				position: relative;
				z-index: 9999;
				width: 100px;
				height: 100px;
				background-size: 80px;
				margin-top: 5px;
			}
		}
	}

	header.main{
		background-image: linear-gradient(top, @shade10, @shade10 60px, transparent 60px, transparent 100%);
	}

	
	.quoute-page{
		overflow: hidden;
		margin-left: 5px;
		margin-right: 5px;

		header.pagina_afbeelding
		{
			width: 65%;
			height: auto;
			position: absolute;
			top: 5px;
			bottom: 5px;

			.imageHolder{
				height: 100%;
			}
		}

		p{
			float: right;
			width: 35%;
		}

	}

	.tagline
	{
		.container
		{
			height: 280px;
		}
	}

	.blocks.home article .container{
		padding: 0;

		header{
			padding-left: 3px;
		}
	}

	.blocks.timelinePiece article section
	{
		.font-size(1.9);
	}

	footer#site-footer {
		.prim-nav{
			width: 1200px;
			position: relative;
			margin: auto;
			padding: 0;

			ul li a{
				width: 100px;

				span{
					.font-size(1.7);
				}
			}
		}
	}

	@-moz-document url-prefix() 
	{
		.page-id-53 .container, .page-id-267 .container
		{
			display: inline-block;
			margin-left: 16.3%;
		}
	}
}

