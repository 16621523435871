
body.lang-fr
{
	nav
	{
		.sec-nav
		{
			ul:nth-child(2)
			{
				li:last-child
				{
					padding-right: 126px;
				}
			}

			.vragen
			{
				a
				{
					font-size: 80%;
				}
			}
		}
	}

	.tagline
	{
		padding-bottom: 15px;
	}
}

@media screen and (min-width: @bp0)
{
	body.lang-fr
	{
		nav
		{
			.sec-nav
			{
				.vragen
				{
					a
					{
						font-size: 100%;
					}
				}
			}
		}
	}
}

@media screen and (min-width: @bp2) 
{
	body.lang-fr
	{
		nav
		{
			.sec-nav
			{
				ul:nth-child(2)
				{
					li:last-child
					{
						padding-right: 0;
					}
				}
			}
		}

		footer
		{
			.prim-nav
			{
				ul li 
				{
					padding-top: 10px;
					a
					{
						width: 120px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: @bp4)
{
	body.lang-fr
	{
		footer
		{
			.prim-nav
			{
				ul li a
				{
					width: 155px;
				}
			}
		}
	}
}